<script setup>
// TODO Better error handling
import {companyInfo} from "@/constants/generalData.json";

const footer = ref();

const {globalFeaturesData} = useGlobalFeaturesData();
const {data: globalFeatures } = await globalFeaturesData();
footer.value = globalFeatures.value.footer
const {getCompanyPage} = useRawPageData();
const { data } = await getCompanyPage(292040);
const { data:tcData } = await getCompanyPage(292039);
const filterCourses = (genre, link) => {
  const interest = useInterest();
  interest.update(genre);
  navigateTo(link);
}
const props = defineProps({
  footerMenu: {
    type: Boolean,
    default: true
  }
})

const showPrivacyContent = ref(false);
const showTermsContent = ref(false);

onMounted(() => {
  const modalEl = document.getElementById('privacy-policy-footer');
  const modalTC = document.getElementById('terms-conditions-footer');

  modalEl.addEventListener('show.bs.modal', () => {
    if (!showPrivacyContent.value) {
      showPrivacyContent.value = true;
    }
  });

  modalEl.addEventListener('hidden.bs.modal', () => {
    showPrivacyContent.value = false; // optional: reset if you want to reload every time
  });

  modalTC.addEventListener('show.bs.modal', () => {
    if (!showTermsContent.value) {
      showTermsContent.value = true;
    }
  });

  modalTC.addEventListener('hidden.bs.modal', () => {
    showTermsContent.value = false; // optional: reset if you want to reload every time
  });
});

</script>

<template>
  <footer id="footer" v-if="footerMenu">
    <div class="container">
      <!-- Footer's head -->
      <div class="footer-head" v-for="contactInfo in footer.filter(contactInfo => contactInfo.component === 'company.contact-info')" :key="contactInfo.id" >
        <div class="row text-center text-lg-start">
          <div class="menu-title mb-lg-3">
            <NuxtImg class="icon-feather-smartphone me-2 mb-3 mb-lg-0" v-if="contactInfo.phoneImage && contactInfo.phoneImage.url"
              :src="contactInfo.phoneImage.url" 
              :alt="contactInfo.phoneImage.alternativeText"
              :title="contactInfo.phoneImage.caption"
              aria-hidden="true"
            />
            <div class="d-lg-inline">{{ contactInfo.menuTitle }}</div>
          </div>
          <div class="ms-lg-4 row d-lg-flex">
            <div class="d-lg-flex flow-flex-row justify-content-between">
              <div>
                <div class="foot-links">{{ contactInfo.daysOfOperation }}</div>
                <div class="foot-links">{{ contactInfo.timesOfOperation }}</div>
              </div>
              <!-- // TODO Move all hard code values to static file or config file for one source of truth --> 
              <div class="foot-links mt-4 mt-lg-0">
                <div><a :href="companyInfo.phone.url">{{ companyInfo.phone.text }}</a></div>
                <div><a :href="companyInfo.internationalPhone.url">{{ companyInfo.internationalPhone.text }}</a></div>
              </div>
              <div class="foot-links">
                <div><a :href="companyInfo.email.url">{{ companyInfo.email.text }}</a></div>
                <div><a :href="companyInfo.smsPhone.url">{{ companyInfo.smsPhone.text }}</a></div> 
              </div>
              <div class="foot-links mb-3">
                <a :href="`http://idtechcamps.zohodesk.com/portal`">Frequently Asked Questions</a> 
              </div>
              <!-- Empty div so that justify content between doesn't push last element to the end -->
              <div></div>
              <div></div>                 
            </div>
          </div>
        </div>
      </div> 
      
        <!-- Footer's body -->
      <hr class="d-none d-lg-block">
      <div class="row py-2 ms-lg-4">
        <div id="collapsible-footer" class="collapsible-footer d-lg-flex px-0 justify-content-between">
          <div class="card px-3 py-2 p-lg-0" v-for="menuItem in footer.filter(menuItem => menuItem.component === 'footer-menu.menu')" :key="menuItem.id">
            <div class="card-header">
                <a class="font-weight-bold d-flex justify-content-between d-lg-none collapsed" 
                    data-bs-toggle="collapse" 
                    :data-bs-target="`#collapse-${menuItem.id}`"
                    aria-expanded="false" 
                    :aria-controls="`collapse-${menuItem.id}`"
                    role="button">
                    {{ menuItem.menuTitle }}
                    <div class="bi bi-plus"></div>
                </a>
                <div class="font-weight-bold d-none d-lg-flex">{{ menuItem.menuTitle }}</div>
            </div>

            <div :id="`collapse-${menuItem.id}`" class="collapse d-lg-flex pb-4 pb-lg-0" data-bs-parent="#collapsible-footer">
              <div class="card-body py-0 d-lg-flex">
                <div class="foot-links col px-0">
                  <template v-for="data in menuItem.menu1">
                    <template v-if="data.genre">
                    <a :key="data.id" @click="filterCourses(data.genre.typeString, data.secondaryMenuLink)" v-if="data.secondaryMenuLink !== null && !data.secondaryMenuLink.includes('seasonal-app/login')">
                      {{ data.secondaryMenuTitle }}
                    </a>
                    </template>
                    <template v-else>
                    <a :href="data.secondaryMenuLink" :key="data.id" v-if="data.secondaryMenuLink !== null && !data.secondaryMenuLink.includes('seasonal-app/login')">
                      {{ data.secondaryMenuTitle }}
                    </a>
                    <a :href="data.secondaryMenuLink" target="__blank" :key="data.id" v-else >
                      {{ data.secondaryMenuTitle }}
                    </a>
                    </template>
                  </template>
                </div>
                <div class="foot-links col px-0 menu2" v-if="menuItem.menu2.length > 0">
                  <a :href="data.secondaryMenuLink" v-for="data in menuItem.menu2" :key="data.id">
                    {{ data.secondaryMenuTitle }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Empty div so that justify content between doesn't push last element to the end -->
          <div></div>
        </div>
      </div>
      <!-- Footer's footer -->
      <hr class="d-none d-lg-block">
      <div class="row footer-foot py-2 px-3 px-lg-0 ms-lg-4">
        <div class="d-flex flex-md-row flex-column-reverse flex-lg-row justify-content-between">
          <div class="mt-4 mt-lg-0">
            <div class="foot-links">        
              <NuxtLink data-bs-toggle="modal" data-bs-target="#privacy-policy-footer">Privacy Policy</NuxtLink>
              <NuxtLink data-bs-toggle="modal" data-bs-target="#terms-conditions-footer">Terms & Conditions</NuxtLink>
              <a href="/#manage_cookies">Manage Cookies</a>
            </div>
            <div class="copywrite" v-for="contactInfo in footer.filter(contactInfo => contactInfo.component === 'company.contact-info')" :key="contactInfo.id">
              <div class="foot-links">{{contactInfo.copyrightContent}}</div>
            </div>
          </div>
          <div v-for="connectWithUs in footer.filter(connectWithUs => connectWithUs.component === 'footer-menu.connect-with-us')" :key="connectWithUs.id">
            <p class="mb-2 foot-links">{{connectWithUs.socialTitle}}</p>
            <div>
              <div class="social-links">
                <NuxtLink v-for="social in connectWithUs.socialShare" :key="social.id" :href="connectWithUs.socialUrl" class="pe-3" target="_blank" rel="nofollow noopener noreferrer">
                  <NuxtImg 
                    :src="social.socialIcon.url"
                    :alt="social.socialIcon.alternativeText"
                    :title="social.socialIcon.caption"
                    aria-hidden="true"
                  />
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </footer>
  <UIModal modalId="privacy-policy-footer" modalClass="modal-dialog-scrollable" modalTitle="iD Tech Privacy Policy"> 
    <GenericContent :data="{content : data.message[0].text}" v-if="showPrivacyContent"/>
  </UIModal>
  <UIModal modalId="terms-conditions-footer" modalClass="modal-dialog-scrollable" modalTitle="iD Tech Terms & Conditions">
    <GenericContent :data="{content : tcData.message[0].text}" v-if="showTermsContent"/>
  </UIModal>
</template>

<style lang="scss">
  $font-size-x-small: .75rem;
  $footer-line-color: #707070;

  body.has-floating-footer #footer {
  padding-bottom: 8rem; 
}

  #footer {
    background: black;
    color: white;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-weight: normal;
    padding-top: 3rem;
    padding-bottom: 3rem;

    hr {
      background-color: $footer-line-color;
    }

    .icon-feather-smartphone {
      width: 20px;
      height: 28px;
    }

    .menu-title {
      font-weight: $font-weight-bold;
      line-height: 1.63;
    }

    .foot-links {
      font-size: $font-size-x-small;
      line-height: 2;
      letter-spacing: 1.1px;
      a {
        display: block;
        color: $color-light-gray;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          font-weight: 600;
          color: white;
          text-decoration: underline;
        }

        &:focus{
          outline: 2px solid white;
          border-radius: 4px;
        }
      } 
      &.menu2  {
        margin: 0 1rem 0 6rem;

        @include media-breakpoint-down(lg) {
          margin: 0;
        }
      }   
    }

    .collapsible-footer {
      color: black;

      .card {
        background-color: black;
        border-bottom: 1px solid $footer-line-color;
        --bs-card-border-radius: none;

        &:first-child {
          border-top: 1px solid $footer-line-color;
        }

        @include media-breakpoint-up(lg) {
          border: none;
          &:first-child {
            border-top: none;
          }
        }

        .card-header {
          background-color: black;
          border: none;
          color: white;

          a {
            color: white;
            text-decoration: none;

            &:not(.collapsed) {
              .bi-plus {
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }

    .footer-foot {
      .social-links {
        a img {
          &:hover {
            background-color: #d9d9d9;
            border-radius: 20px;
            filter: grayscale(1) invert(1) brightness(5.0);
          }
        }
      }
    }
  }
</style>