export const useGlobalFeaturesData = () => {
  const config = useRuntimeConfig()
  
  const transform = (response) => {
    const {sanitize} = useUtils();
    const result = {        
      topNavigation: sanitize(response.data.attributes.topNavigation),
      footer: sanitize(response.data.attributes.footerNavigation),
      companyLogos: sanitize(response.data.attributes.logo),
      heroHeaderGlobals: sanitize(response.data.attributes.heroHeaderGlobals),
      cookieBanner: sanitize(response.data.attributes.cookieBanner),
    }

    return result;
  }
  
  const globalFeaturesData = async (deep = true) => {        
    const url = `/api/global-features`;
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        method: 'GET',
        baseURL: config.public.nuxtAPIUrl,      
        transform: transform
      });
    }

    return await useFetch(url, {
      method: 'GET',
      transform: transform
    });
  }

  const globalFeaturesLocal = async () => {
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch('/api/global-features', {
        baseURL: config.public.nuxtAPIUrl,
      })
    }

    return await useFetch('/api/global-features')
  }

  const setGlobalFeaturesLocal = async (data) => {
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch('/api/global-features', {
        method: 'post',
        baseURL: config.public.nuxtAPIUrl,
        body: data
      })
    }

    return await useFetch('/api/global-features', {
      method: 'post',
      body: data
    })
    
  }

  const locationKeysOrder = (key) => {
    const keys = ['@context', '@type', 'name', 'startDate', 'endDate', 'eventAttendanceMode', 'eventStatus', 'location', 'image', 'description', 'offers', 'organizer'];
    return keys.indexOf(key);
  }

  const reorderLocationStructuredData = (json) => {
    return Object.keys(json).sort((a, b) => locationKeysOrder(a) - locationKeysOrder(b)).reduce(
      (obj, key) => { 
        obj[key] = json[key]; 
        return obj;
      }, 
      {}
    );
  }

  // Define the function to set up page head tags
  const setPageHead = (seoData, path) => {
    const config = useRuntimeConfig()
    const fullPath = `${config.public.nuxtUrl}${path}`;
    if (!seoData.canonicalURL) {
      // If empty, set the canonicalURL to the current page URL based on the rendering mode
      seoData.canonicalURL = fullPath;
    }
    // Custom meta tags for metaSocial data
    const metaSocialTags = seoData.metaSocial.map(social => {
      const tags = [
        { hid: `social-title-${social.id}`, property: `og:${social.socialNetwork.toLowerCase()}:title`, content: social.title },
        { hid: `social-description-${social.id}`, property: `og:${social.socialNetwork.toLowerCase()}:description`, content: social.description },
      ];
      if (social.image && social.image.url) {
        tags.push({ hid: `social-image-${social.id}`, property: `og:${social.socialNetwork.toLowerCase()}:image`, content: social.image.url });
      }

      return tags;
    }).reduce((acc, val) => acc.concat(val), []);

    // Reorganizing structured data markup for location pages
    if (seoData.canonicalURL.includes('/locations/') && seoData.structuredData !== null) {
      seoData.structuredData = reorderLocationStructuredData(seoData.structuredData)
    }
    
    useHead(() => ({
      // Set up the title and other meta tags based on the seoData
      title: seoData.metaTitle,
      meta: [
        { charset: 'UTF-8' },
        { 'http-equiv': 'Content-Language', content: 'en' },
        { name: 'description', content: seoData.metaDescription },
        { name: 'robots', content: ( seoData.metaRobots ? seoData.metaRobots : 'noodp') },
        { name: 'viewport', content: ( seoData.metaViewport ? seoData.metaViewport : 'width=device-width, initial-scale=1.0') },
        { property: 'og:image', content: ( seoData.metaImage ? seoData.metaImage.url : '') },
        { property: 'og:title', content: seoData.metaTitle },
        { property: 'og:description', content: seoData.metaDescription },
        ...metaSocialTags,
      ],

      // Custom script tag for structured data
      script: [
        {
          hid: 'structuredData',
          innerHTML: JSON.stringify(seoData.structuredData),
          type: 'application/ld+json',
        },
      ],

      // Custom link tags for metaSocial data
      link: seoData.metaSocial.map(social => ({
        rel: `${social.socialNetwork}`.toLowerCase(),
        href: social.image && social.image.url ? social.link.url : '', 
      })),
      link:
        { rel: 'canonical', href: seoData.canonicalURL },
    }));
  }

  const getSitemap = async () => {
    
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch('/api/sitemap', {
        baseURL: config.public.nuxtAPIUrl,
      })
    }

    return await useFetch('/api/sitemap')
  }

  const getHeroHeader = async (entity, slug) => {
    const url = slug ? `/api/hero-header?entity=${entity}&slug=${slug}` : `/api/hero-header?entity=${entity}`

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(url, {
        baseURL: config.public.nuxtAPIUrl,
      })
    }

    return await useFetch(url)
  }

  
  return {
    globalFeaturesData,
    globalFeaturesLocal,
    setGlobalFeaturesLocal,
    setPageHead,
    getSitemap,
    getHeroHeader
  }
}